.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-logo-inside {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo-inside {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #092c72;
  background-image: linear-gradient(to right, rgb(77, 118, 184), rgb(22, 3, 84));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #092c72;
  background-image: linear-gradient(to right, rgb(77, 118, 184), rgb(22, 3, 84));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(215, 210, 210);
  font-family: sans-serif;
  font-size: small;
}

.footer__a {
  color: rgb(215, 210, 210);
  font-family: sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.grecaptcha-badge {
  visibility: hidden;
}


.input {
  display: flex;
  width: 95%;
  position: relative;
  align-items: center;
}

.input__box {
  width: 100%;
  border-radius: 50px;
  padding: 20px 30px;
  font-size: 25px;
  border: none;
  transition: 0.2s;
  box-shadow: inset 0 0 5px black;
}

.input__box:focus {
  box-shadow: 0 0 10px 1000px rgba(0, 0, 0, 0.5);
  outline: none;
}

.input_submit {
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 12px;
  border-radius: 50px;
  right: 0px;
  border: none;
  font-size: 15px;
  background-color: #2f74c0;
  color: white;
  transition: 0.2s all;
  box-shadow: 0 0 10px black;
}

.input_submit:hover {
  background-color: #388ae2;
}

.input_submit:active {
  transform: scale(0.8);
  box-shadow: 0 0 5px black;
}